<template>
  <div class="p-field">
    <label :for="fieldStr">{{ label }}</label>
    <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"
      ><i class="pi pi-info-circle"></i
    ></small>
    <div class="p-fileupload p-fileupload-advanced p-component">
      <div class="p-fileupload-buttonbar" style="padding-left: 0">
        <div v-if="Object.keys(fileNames).length != 0">
          {{ fileNames }}
          <!-- <p v-for="fileItem in fileNames" :key="fileItem.id">
            {{ fileItems.name }}
          </p> -->
        </div>
        <div v-else-if="curFileName">
          {{ curFileName }}
        </div>
        <FileUpload
          ref="fileUpload"
          :id="fieldStr"
          mode="basic"
          v-model="serviceF"
          name="file"
          :url="url || apiUrl + '/' + service.basePath + '/upload'"
          @uploader="onUploader"
          :auto="true"
          chooseLabel="Загрузить файл"
          :multiple="multiple"
          :class="
            service.errors && service.errors[fieldStr]
              ? 'p-invalid is-invalid'
              : ''
          "
          :maxFileSize="20000000"
          :showUploadButton="false"
          :showCancelButton="false"
          :customUpload="true"
        ></FileUpload>
      </div>

      <FileGalleria
        v-if="multiple"
        :service="service"
        :field="field"
        :edit="true"
      />
    </div>
    <div>
      <p class="helpText">{{ helpText }}</p>
    </div>
    <div
      v-if="service.errors && service.errors[fieldStr]"
      class="invalid-feedback"
    >
      {{ service.errors ? service.errors[fieldStr] : null }}
    </div>
    <Toast />
  </div>
</template>

<script>
import FileUpload from "primevue/fileupload";
import FileGalleria from "../FileGalleria.vue";
import http from "../../../utils/http-common";
import Toast from "primevue/toast";

export default {
  components: { FileUpload, FileGalleria },
  props: {
    service: Object,
    field: String,
    label: String,
    multiple: Boolean,
    url: String,
    helpText: String,
    tooltip: String,
    curFileName: String,
    Toast,
  },
  data() {
    return {
      id: Math.random().toString(36).substr(2, 9) + new Date().getTime(),
      fileNames: {},
    };
  },
  computed: {
    fieldStr() {
      if (typeof this.field === "object") {
        return this.field.join(".");
      }

      return this.field;
    },
    serviceF: {
      get() {
        if (typeof this.field === "object") {
          let serviceF = this.service;

          for (let f of this.field) {
            if (serviceF[f] === undefined) {
              return null;
            }
            serviceF = serviceF[f];
          }

          return serviceF;
        }

        return this.service[this.field];
      },
      set(v) {
        if (typeof this.field === "object") {
          // Перебором присвоение не сработало, поэтому сравниваем по кол-ву элементов
          if (this.service[this.field[0]] === undefined) {
            this.service[this.field[0]] = {};
          }
          if (this.field.length === 2) {
            this.service[this.field[0]][this.field[1]] = v;
          } else if (this.field.length === 3) {
            if (this.service[this.field[0]][this.field[1]] === undefined) {
              this.service[this.field[0]][this.field[1]] = {};
            }
            this.service[this.field[0]][this.field[1]][this.field[2]] = v;
          }
        } else {
          this.service[this.field] = v;
        }
      },
    },
  },
  methods: {
    async onUploader(data) {
      let files = data.files;
      let success = true;
      let formData = new FormData();
      formData.append("field", this.field);

      this.service.tempId = this.id;
      for (let file of files) {
        formData.set("file", file, file.name);
        formData.append("attribute", this.fieldStr);
        if (this.service.id) {
          formData.append("objectId", this.service.id);
        } else {
          formData.append("tempObjectId", this.service.tempId);
        }

        await http
          .post("/" + this.service.basePath + "/upload", formData)
          .then((response) => {
            if (this.multiple) {
              this.serviceF = this.serviceF || [];
              this.fileNames = response.data;
              this.serviceF.push(response.data.data);
              this.$toast.add({
                severity: "success",
                summary: "Успешно",
                detail: "Файлы успешно загружены",
                life: 10000,
              });
            } else {
              this.serviceF = response.data;
              console.log(response.data);
              this.fileNames = response.data;
              this.$toast.add({
                severity: "success",
                summary: "Успешно",
                detail: response.data + " успешно загружен",
                life: 10000,
              });
            }
          })
          .catch((error) => {
            success = false;
            this.$toast.add({
              severity: "error",
              summary: "Ошибка",
              detail: file.name + ": " + error,
              life: 10000,
              group: "bl",
            });
          });
      }

      if (success) {
        this.$toast.add({
          severity: "info",
          summary: "Успешно",
          detail: "Файл загружен",
          life: 3000,
          group: "bl",
        });
      }

      this.$refs.fileUpload.clear();
    },
  },
};
</script>
